var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.url != null
    ? _c("iframe", {
        attrs: {
          src: _vm.url,
          width: "100%",
          height: "100%",
          frameborder: "0",
        },
      })
    : _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _c(
            "v-alert",
            { attrs: { dense: "", border: "left", type: "warning" } },
            [_vm._v(" Cannot load PDF ")]
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }