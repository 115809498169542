var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("ASuccessWithoutReload", {
        attrs: { api: _vm.api },
        on: {
          close: function () {
            _vm.$router.push({ name: "PageInvoiceXeroList" })
          },
        },
      }),
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ARedirectBack"),
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c(
            "v-col",
            {
              staticClass: "mx-auto",
              attrs: {
                align: "center",
                justify: "center",
                cols: "6",
                sm: "12",
                md: "6",
                lg: "6",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "pa-3", attrs: { "elevation-1": "" } },
                [
                  _c(
                    "v-toolbar",
                    { staticClass: "secondary white--text text-h5" },
                    [_vm._v(" Send email ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-4 pt-3" },
                    [
                      _c("AError", { attrs: { api: this.api } }),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-start" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              label: "Send email to (required)",
                            },
                            model: {
                              value: _vm.data.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "email", $$v)
                              },
                              expression: "data.email",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-start" },
                        [
                          _c("v-combobox", {
                            attrs: {
                              items: _vm.cc_email,
                              label: "CC email to",
                              multiple: "",
                              dense: "",
                              chips: "",
                              outlined: "",
                              hint: "Press enter button to confirm the email ( max 3 emails)",
                            },
                            on: { change: _vm.handleChange },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function (ref) {
                                  var item = ref.item
                                  var index = ref.index
                                  return [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-chip",
                                                    _vm._g(
                                                      {
                                                        staticClass: "my-2",
                                                        staticStyle: {
                                                          width: "100%",
                                                          "text-overflow":
                                                            "ellipsis",
                                                          overflow: "hidden",
                                                        },
                                                        attrs: {
                                                          label: "",
                                                          outlined: "",
                                                          close: "",
                                                          color: "green",
                                                        },
                                                        on: {
                                                          "click:close":
                                                            function ($event) {
                                                              return _vm.removeItem(
                                                                index
                                                              )
                                                            },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        " " + _vm._s(item) + " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(" " + _vm._s(item) + " "),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.selectedClient,
                              callback: function ($$v) {
                                _vm.selectedClient = $$v
                              },
                              expression: "selectedClient",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex flex-column justify-start pb-0",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c("v-combobox", {
                                staticStyle: {
                                  "border-radius": "4px 0px 0px 4px",
                                },
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  items: _vm.picOptions,
                                  loading: _vm.isLoadingPIC,
                                  label: "Salesperson",
                                },
                                on: { change: _vm.handleChangeSalesperson },
                                model: {
                                  value: _vm.data.salesperson,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "salesperson", $$v)
                                  },
                                  expression: "data.salesperson",
                                },
                              }),
                              _c(
                                "v-btn",
                                {
                                  staticStyle: {
                                    "border-radius": "0px 4px 4px 0px",
                                    height: "40px",
                                  },
                                  attrs: { small: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addCCEmail(
                                        _vm.data.salesperson
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Add to cc ")]
                              ),
                            ],
                            1
                          ),
                          _vm.isshowAssignedSalesperson
                            ? _c("v-checkbox", {
                                staticStyle: { "margin-top": "-20px" },
                                attrs: { label: "Set as current salesperson" },
                                model: {
                                  value: _vm.isSetAsSalesperson,
                                  callback: function ($$v) {
                                    _vm.isSetAsSalesperson = $$v
                                  },
                                  expression: "isSetAsSalesperson",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c("v-combobox", {
                                staticStyle: {
                                  "border-radius": "4px 0px 0px 4px",
                                },
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  loading: _vm.isLoadingPIC,
                                  items: _vm.picOptions,
                                  label: "Renewal PIC",
                                },
                                on: { change: _vm.handleChangePIC },
                                model: {
                                  value: _vm.data.pic_email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "pic_email", $$v)
                                  },
                                  expression: "data.pic_email",
                                },
                              }),
                              _c(
                                "v-btn",
                                {
                                  staticStyle: {
                                    "border-radius": "0px 4px 4px 0px",
                                    height: "40px",
                                  },
                                  attrs: { small: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addCCEmail(_vm.data.pic_email)
                                    },
                                  },
                                },
                                [_vm._v(" Add to cc ")]
                              ),
                            ],
                            1
                          ),
                          _vm.isshowAssignedPIC
                            ? _c("v-checkbox", {
                                staticStyle: { "margin-top": "-20px" },
                                attrs: { label: "Set as current renewal PIC" },
                                model: {
                                  value: _vm.isSetAsRenewalPIC,
                                  callback: function ($$v) {
                                    _vm.isSetAsRenewalPIC = $$v
                                  },
                                  expression: "isSetAsRenewalPIC",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("div", {
                        staticClass: "d-flex flex-column justify-start pb-0 ",
                        staticStyle: { "margin-top": "-10px" },
                      }),
                      _c("div", {
                        staticClass: "d-flex justify-center pb-2 mb-3",
                        staticStyle: { "margin-top": "-15px" },
                      }),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-start pt-3" },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.selectedInvoices,
                              dense: "",
                              "item-text": "text",
                              "item-value": "value",
                              outlined: "",
                              label: "Invoices",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleChangeInvoice(
                                  _vm.data.invoices
                                )
                              },
                            },
                            model: {
                              value: _vm.data.invoices,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "invoices", $$v)
                              },
                              expression: "data.invoices",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-end" },
                        [
                          _c(
                            "v-btn",
                            { attrs: { color: "primary", text: "" } },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                loading: _vm.api.isLoading,
                                disabled: !_vm.isEnableSendEmail,
                              },
                              on: { click: _vm.validateInput },
                            },
                            [_vm._v(" Confirm ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticStyle: { "min-height": "700px" },
              attrs: { cols: "6", lg: "6", md: "6" },
            },
            [
              _vm.isLoadingPdf
                ? _c("v-skeleton-loader", {
                    ref: "skeleton",
                    attrs: { type: "image" },
                  })
                : _c("PageReviewXeroInvoice", { attrs: { url: _vm.pdfLink } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }